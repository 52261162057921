import { Injectable } from '@angular/core';
import { QueueData } from '../models/queue-data';
import * as signalR from "@microsoft/signalr";
import { Settings, SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(private settingsService: SettingsService) {}

  public isReconnecting: boolean = false;
  public data: QueueData = new QueueData();
  public broadcastedData: QueueData = new QueueData();
  private hubConnection: signalR.HubConnection

  public startConnection = () => {
    this.settingsService.getSettings().subscribe((data: Settings) => {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(data.ServerUrl + '/queue', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.addTransferQueueDataListener();
        this.addBroadcastQueueDataListener();

        this.hubConnection.onreconnecting(()=> {
          this.isReconnecting = true;
        });
    
        this.hubConnection.onreconnected(()=>{
          this.isReconnecting = false;
        });
        
        console.log('Connection started');
      })
      .catch(err => console.log('Error while starting connection: ' + err))
    });


  }

  public broadcastQueueData = () => {
    this.hubConnection.invoke('broadcastqueuedata', this.data)
      .catch(err => console.error(err));
  }

  private addTransferQueueDataListener = () => {
    this.hubConnection.on('transferqueuedata', (data) => {
      this.broadcastedData = data;
      console.log(data);
    });
  }

  private addBroadcastQueueDataListener = () => {
    this.hubConnection.on('broadcastqueuedata', (data) => {
      this.broadcastedData = data;
    })
  }
}
