import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { SignalRService } from './services/signal-r.service';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { SettingsComponent } from './components/settings/settings.component';
import { Storage } from '@ionic/storage';
import { Settings } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
 
  constructor(
    private storage: Storage,
    private modalController: ModalController,
    private insomnia: Insomnia,
    private platform: Platform,
    public signalRService: SignalRService, 
    private navCtrl: NavController) {
  }

  async ngOnInit() {
    if (this.platform.is('capacitor')) await this.insomnia.keepAwake();

    this.storage.get('settings').then((settings: Settings)=>{
      if (settings) {
        this.setupSignalrConnection(settings);
      }
      else {
        this.showSettingsDialog();
      }
    });
  }

  async ngOnDestroy() {
    if (this.platform.is('capacitor')) await this.insomnia.allowSleepAgain();
  }

  async showSettingsDialog() {
    const modal = await this.modalController.create({
      component: SettingsComponent,
      swipeToClose: false
    });

    modal.onWillDismiss().then((data) => {
      const settings: Settings = {
        ServerUrl: data['data'].ServerUrl,
        StartupMode: data['data'].StartupMode
      }
      this.setupSignalrConnection(settings);
    });

    return await modal.present();
  }

  private setupSignalrConnection(settings: Settings){
    this.signalRService.startConnection();

    if(settings.StartupMode === 'master') this.navCtrl.navigateRoot('master-node');
    if(settings.StartupMode === 'client') this.navCtrl.navigateRoot('client-node');
  }

}
