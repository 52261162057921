import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'node-selection',
    pathMatch: 'full'
  },
  {
    path: 'client-node',
    loadChildren: () => import('./client-node/client-node.module').then( m => m.ClientNodePageModule)
  },
  {
    path: 'master-node',
    loadChildren: () => import('./master-node/master-node.module').then( m => m.MasterNodePageModule)
  },
  {
    path: 'node-selection',
    loadChildren: () => import('./node-selection/node-selection.module').then( m => m.NodeSelectionPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
