import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SettingsComponent } from "./settings/settings.component";


const PAGE_COMPONENTS = [
  SettingsComponent
];

@NgModule({
  declarations: [PAGE_COMPONENTS],
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule
  ],
  exports:[PAGE_COMPONENTS],
})
export class ComponentsModule { }
