import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Settings, SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {

  settingsForm: FormGroup;
  constructor(private modalController: ModalController,
    private settingsService: SettingsService) { 

    this.settingsForm = new FormGroup({
      ServerUrl: new FormControl('https://qd-api.skilpos.nl', [Validators.required]),
      StartupMode: new FormControl('client', [Validators.required])
    });
  }

  async save(){
    let data: Settings = Object.assign({}, this.settingsForm.value);
    data = Object.assign({}, data);

    await this.settingsService.updateSettings(data);
    this.modalController.dismiss(data);
  }
}
